html {
    background-color: #fafafa;
}

.login {
	display: flex;
	align-items: center;
	-ms-flex-align: center;
	justify-content: center;
}

.card-footer-item {
  padding: 0;
}

.is-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.is-middle {
  border-radius: 0;
}

.is-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}